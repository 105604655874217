import amazonIntegration from '@moovly/integrations-amazon';
import bigcommerceIntegration from '@moovly/integrations-bigcommerce';
import shopifyIntegration from '@moovly/integrations-shopify';
import bolComIntegration from '@moovly/integrations-bolcom';
import { getThemingConfig } from '@moovly/whitelabel';
import { IntegrationConfig } from '@moovly/integrations-core';

const integrationMapping: { [key: string]: IntegrationConfig | IntegrationConfig[] } = {
  amazon: amazonIntegration,
  bigcommerce: bigcommerceIntegration,
  bolcom: bolComIntegration,
  shopify: shopifyIntegration,
};

const videomaker = getThemingConfig('videomaker');

const videomakerArray = !videomaker || Array.isArray(videomaker) ? videomaker : [videomaker];

const integrations: IntegrationConfig[] = Object.keys(integrationMapping).reduce<IntegrationConfig[]>((result, key) => {
  if (!videomakerArray || videomakerArray.includes(key)) {
    const integration = integrationMapping[key];
    if (Array.isArray(integration)) {
      result = [...result, ...integration];
    } else {
      result.push(integration);
    }
    return result;
  }
  return result;
}, []);

export default integrations;
