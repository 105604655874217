import { Integration, PageSense } from '@moovly/clients';
import { signInToIntegrationFromOauth, useIntegration, useIntegrations } from '@moovly/integrations-core';
import { Button } from '@moovly/ui';
import * as React from 'react';

interface IntegrationButtonContainerProps {
  integration: Integration;
  label?: string;
  buttonAtBottomPage?: boolean;
  onConnected?: () => void;
}

const IntegrationButtonContainer: React.FC<IntegrationButtonContainerProps> = ({
  integration,
  buttonAtBottomPage,
  label,
  onConnected,
}) => {
  const integrationConfig = useIntegration(integration);
  const { addUserIntegrations } = useIntegrations();
  const handleConnect = React.useCallback(
    (extraProps?: any) => {
      if (integrationConfig?.card?.payload?.btnLink) {
        window.open(integrationConfig?.card?.payload?.btnLink);
      } else {
        signInToIntegrationFromOauth(
          integration,
          (token) => {
            addUserIntegrations(integration, token);
            if (onConnected) {
              if (integration === Integration.AmazonSeller) {
                PageSense.track('trackEvent', 'Amazon store connected');
              }

              onConnected();
            }
          },
          extraProps,
        );
      }
    },
    [integration, integrationConfig, addUserIntegrations, onConnected],
  );
  const ConnectButton = React.useMemo(() => {
    if (integrationConfig?.customComponents && !!integrationConfig.customComponents.connectButton) {
      return integrationConfig.customComponents.connectButton;
    }
    return null;
  }, [integrationConfig]);
  if (!integrationConfig) {
    return null;
  }
  return ConnectButton ? (
    <ConnectButton
      // @ts-ignore
      openAboveElement={buttonAtBottomPage}
      onConnect={handleConnect}
    />
  ) : (
    <Button color="pink" onClick={handleConnect} label={label} />
  );
};

export default IntegrationButtonContainer;
