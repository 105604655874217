import * as React from 'react';
import { Navigate, NavigateProps, useParams } from 'react-router-dom';

const NavigateWithParams: React.FC<NavigateProps> = ({ to, ...props }) => {
  const params = useParams();
  const transformedTo = React.useMemo(
    () =>
      Object.keys(params).reduce((res, param) => res.replaceAll(`:${param}`, params[param] as string), to as string),
    [params, to],
  );
  return <Navigate to={transformedTo} {...props} />;
};

export default NavigateWithParams;
