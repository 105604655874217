import { DashboardLayout } from '@moovly/ui';
import modules from '../modules';
import * as React from 'react';
import { t } from '@moovly/i18n';
import { Route } from '../types/route.interfaces';
import { Footer, Link } from '@moovly/dashboard-global';
import { TopRight } from '../components/DashboardLayout';
import { inject } from 'mobx-react';
import { UserProfile } from '@moovly/clients/lib/resources/users/users.interfaces';
import { createE2ESelector, OAuth, Scope } from '@moovly/clients';
import { License } from '@moovly/clients/lib/resources/licenses/licenses.interfaces';
import { ZohoContainer } from '@moovly/zoho';
import { PermissionProvider, useOwnedScopes } from '@moovly/permissions';
import { AccessUserResponse } from '@moovly/clients/lib/resources/access/access.interfaces';
import { RootState } from '../store';
import useIframe from '../hooks/useIframe';
import SidebarBottom from '../components/DashboardLayout/SidebarBottom';
interface DashboardContainerProps {
  children: React.ReactNode;
  user?: UserProfile;
  accessData?: AccessUserResponse;
  license?: License;
}

const getE2eSelector = (route: Route) => {
  if (route.path) {
    return createE2ESelector(`menu-${route.path.slice(1).replaceAll('/', '-')}`);
  } else if (route.sidebar && route.sidebar.id) {
    return createE2ESelector(`menu-${route.sidebar.id}`);
  } else if (route.sidebar) {
    return createE2ESelector(`menu-${route.sidebar.translationKey}`);
  } else {
    return null;
  }
};

const DashboardContainer: React.FC<DashboardContainerProps> = ({ children, user, license, accessData }) => {
  const scopes = useOwnedScopes();
  const { isEmbedded } = useIframe();
  const menuItems = React.useMemo(() => {
    return Object.values(modules).flatMap((module) => {
      const formatSidebarRoutes = (routes: Route[]): any => {
        return routes
          .filter((r) => !!r.sidebar && (!r.scope || scopes.includes(r.scope)))
          .map((route) => ({
            route: route.path,
            icon: route.sidebar!.icon,
            label: t(route.sidebar!.translationKey),
            subItems: route.children && formatSidebarRoutes(route.children),
            extraAttributes: getE2eSelector(route),
          }));
      };
      return formatSidebarRoutes(module.routes);
    });
  }, [scopes]);

  const email = React.useMemo(() => {
    return user && user.active_email.email;
  }, [user]);

  const handleLogout = React.useCallback(() => {
    OAuth.logout();
  }, []);
  return (
    <PermissionProvider scopes={(accessData ? [...accessData.scopes] : []) as Scope[]}>
      {user && license && <ZohoContainer profile={user} license={license} alwaysShowChat />}
      <DashboardLayout
        linkComponent={Link}
        menuItems={menuItems}
        showTopBar={!isEmbedded}
        logoHeight={isEmbedded ? 50 : 30}
        sidebarBottom={<SidebarBottom />}
        logo={isEmbedded ? '/assets/images/moovly-vm-logo-small-embed.svg' : '/assets/images/moovly-vm-logo-small.svg'}
        rightTop={email && <TopRight email={email} onLogout={handleLogout} />}
        footer={<Footer />}
      >
        {children}
      </DashboardLayout>
    </PermissionProvider>
  );
};
export default inject<RootState>(({ user, license }) => ({
  user: user.data,
  accessData: user.access,
  license: license.data,
}))(DashboardContainer);
