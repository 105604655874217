import * as React from 'react';
import { inject } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { PageSense, Integration } from '@moovly/clients';
import { RootState } from '../../../store';
import { IntegrationCard, IntegrationListWrapper, useHasIntegration } from '@moovly/integrations-core';
import { BolComVideoMaker, BolComVideoMakerStep } from '@moovly/integrations-bolcom';

interface BolComVideoMakerPageProps {}
const BolComVideoMakerPage: React.FC<BolComVideoMakerPageProps> = () => {
  const { hasIntegration, integrationsLoaded } = useHasIntegration(Integration.BolCom);

  const { state } = useLocation() as {
    state?: { skipProductSelect?: boolean };
  };
  const handleFinished = React.useCallback(() => {
    PageSense.track('trackEvent', 'Videomaker video created');
  }, []);
  if (!integrationsLoaded || !hasIntegration) {
    return (
      <IntegrationListWrapper>
        <IntegrationCard integration={Integration.BolCom} />
      </IntegrationListWrapper>
    );
  }

  return (
    <BolComVideoMaker
      onFinished={handleFinished}
      startAtStep={state && state.skipProductSelect ? BolComVideoMakerStep.SelectBolComTemplate : undefined}
    />
  );
};

export default BolComVideoMakerPage;
