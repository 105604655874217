import { LocalStorageService } from '@moovly/clients';

if (LocalStorageService.getItem('dymanicRetried') !== 'true') {
  const originConsoleError = console.error;

  console.error = (...args) => {
    args.forEach((arg) => {
      if (arg instanceof Error && arg.message.indexOf('Failed to fetch dynamically imported module:') >= 0) {
        LocalStorageService.setItem('dymanicRetried', 'true');
        window.location.reload();
      }
    });
    originConsoleError(...args);
  };
}
LocalStorageService.removeItem('dymanicRetried');
