import { lazy } from 'react';
import { IconVideo } from '@moovly/ui';
import { Route } from '../../types/route.interfaces';
import NavigateWithParams from '../../router/NavigateWithParams';

const JobOverview = lazy(() => import('./pages/JobOverview'));
const JobVideoTranslatePage = lazy(() => import('./pages/JobVideoTranslate'));
const JobVideoReviewPage = lazy(() => import('./pages/JobVideoReview'));
const JobVideoEditPage = lazy(() => import('./pages/JobVideoEdit'));

const dashboardRoutes: Route[] = [
  {
    sidebar: {
      icon: IconVideo,
      translationKey: 'menu.renders',
    },
    path: '/videos',
    element: <JobOverview />,
  },
  {
    path: '/videos/:id/translate',
    element: <JobVideoTranslatePage />,
  },
  {
    path: '/videos/:id/review',
    element: <JobVideoReviewPage />,
  },
  {
    path: '/videos/:id/edit',
    element: <JobVideoEditPage />,
  },
  {
    path: '/videos/jobs/:id/translate',
    element: <NavigateWithParams replace to="/videos/:id/translate" />,
  },
  {
    path: '/videos/jobs/:id/review',
    element: <NavigateWithParams replace to="/videos/:id/review" />,
  },
  {
    path: '/videos/jobs/:id/edit',
    element: <NavigateWithParams replace to="/videos/:id/edit" />,
  },
];

export default dashboardRoutes;
