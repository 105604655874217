import * as React from 'react';

import { inject } from 'mobx-react';
import { UserProfile } from '@moovly/clients/lib/resources/users/users.interfaces';
import { Scope } from '@moovly/clients';
import { License } from '@moovly/clients/lib/resources/licenses/licenses.interfaces';
import { PermissionProvider } from '@moovly/permissions';
import { AccessUserResponse } from '@moovly/clients/lib/resources/access/access.interfaces';
import { CreditsProvider } from '@moovly/credits';
import { PaymentProvider } from '@moovly/payment-gateway';
import { RootState, UserStore } from '../store';
import { IntegrationsProvider } from '@moovly/integrations-core';
import { SettingsProvider } from '@moovly/moovly-settings';
import integrations from '../config/integrations';
import { useIntents } from '@moovly/dashboard-global';
interface RootLoggedInContainerProps {
  children: React.ReactNode;
  user?: UserProfile;
  thirdpartyAccessTokens?: any;
  isAuthenticated: boolean;
  accessData?: AccessUserResponse;
  license?: License;
  userLoading?: boolean;
}

const RootLoggedInContainer: React.FC<RootLoggedInContainerProps> = ({
  children,
  user,
  license,
  userLoading,
  isAuthenticated,
  thirdpartyAccessTokens,
  accessData,
}) => {
  useIntents({ user: user, onClearRedirectUrl: UserStore.redirectDone });
  const always = (
    <IntegrationsProvider
      userIntegrationsLoading={userLoading || false}
      integrations={integrations}
      userIntegrations={thirdpartyAccessTokens || {}}
    >
      {children}
    </IntegrationsProvider>
  );
  if (!isAuthenticated) {
    return always;
  }
  return (
    <PermissionProvider scopes={(accessData ? [...accessData.scopes] : []) as Scope[]}>
      <SettingsProvider>
        <PaymentProvider chargeBeeSite={import.meta.env.MOOVLY_APP_CHARGEBEE_SITE}>
          <CreditsProvider>{always}</CreditsProvider>
        </PaymentProvider>
      </SettingsProvider>
    </PermissionProvider>
  );
};
export default inject<RootState>(({ user, license }) => ({
  user: user.data || undefined,
  userLoading: !user.loaded,
  thirdpartyAccessTokens: user.thirdpartyAccessTokens,
  accessData: user.access,
  license: license.data,
}))(RootLoggedInContainer);
