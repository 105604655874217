import { Spinner } from '@moovly/ui';
import { Provider } from 'mobx-react';
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import useAuth from './hooks/auth';
import useI18n from './hooks/i18n';
import Router from './Router';
import rootStore from './store';
import { WhitelabelPageHeaders } from '@moovly/whitelabel';
import SentrySevice from './services/Sentry';
import RootProviderContainer from './containers/RootProviderContainer';

function App() {
  const { authChecked, isAuthenticated, userId } = useAuth();

  React.useEffect(() => {
    SentrySevice.setUser(userId);
  }, [userId]);
  const loaded = useI18n({ isAuthenticated });
  if (!loaded || !authChecked) {
    return <Spinner />;
  }
  const router = <Router isLoggedIn={isAuthenticated} />;
  return (
    <>
      <WhitelabelPageHeaders />
      <BrowserRouter>
        <Provider {...rootStore}>
          <RootProviderContainer isAuthenticated={isAuthenticated}>{router}</RootProviderContainer>
        </Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
