import { t } from '@moovly/i18n';
import * as React from 'react';
import { Link } from 'react-router-dom';
import './OverviewPreviewSection.scss';
interface OverviewPreviewSectionProps {
  title: string;
  showMoreLink?: string;
  showMoreText?: string;
  children: React.ReactNode;
}

const OverviewPreviewSection: React.FC<OverviewPreviewSectionProps> = ({
  title,
  showMoreLink,
  children,
  showMoreText = t('global.showMore'),
}) => {
  return (
    <section className="OverviewPreviewSection">
      <div className="OverviewPreviewSectionHeader">
        <h3>{title}</h3>
        {showMoreLink && <Link to={showMoreLink}>{showMoreText}</Link>}
      </div>
      {children}
    </section>
  );
};

export default OverviewPreviewSection;
