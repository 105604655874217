import * as React from 'react';
import classnames from 'classnames';

import './Page.scss';
interface PageProps {
  title?: string;
  description?: string;
  actions?: React.ReactNode;
  error?: React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
}
const Page: React.FC<PageProps> = ({ title, description, children, actions, error }) => (
  <div className="Page">
    {error}
    {(title || description || actions) && (
      <div
        className={classnames(
          'Page--Header mb-4 d-flex align-items-center',
          title ? 'justify-content-between' : 'justify-content-end',
        )}
      >
        <div className="Page-HeaderContent">
          {title && <h2>{title}</h2>}
          {description && <p>{description}</p>}
        </div>
        {actions && <div className="Page--Actions d-flex align-items-center">{actions}</div>}
      </div>
    )}
    {children}
  </div>
);

export default Page;
