import { OAuth } from '@moovly/clients';
import * as React from 'react';
import Helmet from 'react-helmet';
import { ActivateAccountContainer } from '@moovly/dashboard-global';
import LayoutWithoutSidebar from '../components/LayoutWithoutSidebar';

const ActivateAccount: React.FC = () => {
  React.useEffect(() => {
    OAuth.logout();
  }, []);
  return (
    <>
      <Helmet title={'Activate account'} />
      <LayoutWithoutSidebar>
        <ActivateAccountContainer />
      </LayoutWithoutSidebar>
    </>
  );
};

export default ActivateAccount;
