import * as React from 'react';

export const isInIframe = () => window.location !== window.parent.location;

const useIframe = () => {
  const isEmbedded = React.useMemo(isInIframe, []);

  return { isEmbedded };
};

export default useIframe;
