import { t } from '@moovly/i18n';
import { Instructions } from '@moovly/oauth';
import classNames from 'classnames';
import * as React from 'react';
import './FirstTimeGreeting.scss';

interface FirstTimeGreetingProps {
  connectButton?: any;
  instructions?: string;
}

const FirstTimeGreeting: React.FC<FirstTimeGreetingProps> = ({ connectButton, instructions }) => {
  return (
    <div className="FirstTimeGreeting">
      {instructions && <Instructions instructions={instructions} defaultCollapsed={true} />}
      <div
        className={classNames('FirstTimeGreeting--LaptopWrapper', {
          small: !!instructions,
        })}
      >
        <img className="Laptop" src="/assets/images/Laptopbackground.png" alt="Moovly banner mockup" />

        <div className="IframeWrapper">
          <iframe
            title="Welcome video"
            id="home-video"
            src="https://player.vimeo.com/video/759102538?h=701b15a39e&autoplay=1&loop=1&autopause=0&muted=1&dnt=1"
            allow="fullscreen;"
          />
        </div>
      </div>
      <div className="FirstTimeGreeting--Content">
        <h1>{t('landingPage.welcome.title')}</h1>
        <p>{t('landingPage.welcome.description')}</p>
      </div>
      <div className="FirstTimeGreeting--Button">{connectButton}</div>
    </div>
  );
};

export default React.memo(FirstTimeGreeting);
