import { t } from '@moovly/i18n';
import { LottieAnimation } from '@moovly/lottie';
import { Button } from '@moovly/ui';
import * as React from 'react';
import { Link } from 'react-router-dom';
import './SuccessfullyConnected.scss';

interface SuccessfullyConnectedProps {}

const SuccessfullyConnected: React.FC<SuccessfullyConnectedProps> = () => {
  return (
    <div className="SuccessfullyConnected">
      <div className="Animation">
        <LottieAnimation loop={false} path="/assets/lottie/global/success.json" />
      </div>
      <div>
        <h2>{t('landingPage.connected.title')}</h2>
        <p>{t('landingPage.connected.description')}</p>
        <Button linkComponent={Link} href="/video-maker" label={t('landingPage.connected.cta')} />
      </div>
    </div>
  );
};

export default React.memo(SuccessfullyConnected);
