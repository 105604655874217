import { t } from '@moovly/i18n';
import { IntegrationsList } from '@moovly/integrations-core';
import Page from '../../../components/Page';

const InstegrationSettingsPage = () => {
  return (
    <Page title={t('settings.integrations.title')} description={t('settings.integrations.description')}>
      <IntegrationsList />
    </Page>
  );
};

export default InstegrationSettingsPage;
