import * as React from 'react';
import { BrandColorSettings, SettingsSaveButton, BrandLogosSettings } from '@moovly/moovly-settings';

const PreferencesBrandsPage = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-12 me-4">
          <BrandColorSettings />
        </div>
        <div className="col-md-12 me-4">
          <BrandLogosSettings />
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <SettingsSaveButton />
      </div>
    </>
  );
};

export default PreferencesBrandsPage;
