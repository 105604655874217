import * as React from 'react';
import './FirstTimeGreetingButton.scss';
interface FirstTimeGreetingButtonProps {
  children: React.ReactNode;
  src?: string;
}

const FirstTimeGreetingButton: React.FC<FirstTimeGreetingButtonProps> = ({ children, src }) => {
  return (
    <div className="FirstTimeGreetingButton">
      <div className="FirstTimeGreetingButton--Actions">{children}</div>
      {src && (
        <>
          <div>
            <span>via</span>
          </div>
          <img src={src} alt="connect logo" />
        </>
      )}
    </div>
  );
};

export default FirstTimeGreetingButton;
