import { t } from '@moovly/i18n';
import * as React from 'react';
import OverviewPreviewSection from '../components/OverviewPreviewSection';

import { useNavigate } from 'react-router-dom';
import { JobVideosOverview } from '@moovly/dashboard-renders';
import { useWhitelabelProperty } from '@moovly/whitelabel';
import { jobActionUrls } from '../../renders';
import { getJobOverviewPage, getJobTagFilter } from '../../../config/videomaker';
import { VideoMaker, useIntegrations } from '@moovly/integrations-core';

interface VideoMakerOverviewContainerProps {}

const VideoMakerOverviewContainer: React.FC<VideoMakerOverviewContainerProps> = () => {
  const navigate = useNavigate();
  const { userIntegrations } = useIntegrations();
  const videomaker = useWhitelabelProperty<VideoMaker | VideoMaker[]>('videomaker');

  const handleProductSelect = React.useCallback(() => {
    let path = '/video-maker/amazon';
    if (videomaker === 'bigcommerce') {
      path = '/video-maker/bigcommerce';
    } else if (videomaker === 'shopify') {
      path = '/video-maker/shopify';
    }
    navigate(path, {
      state: {
        skipProductSelect: true,
      },
    });
  }, [navigate, videomaker]);

  const ProductOverview = React.useMemo(() => {
    return getJobOverviewPage(videomaker, userIntegrations);
  }, [videomaker, userIntegrations]);
  const jobTagFilter = React.useMemo(() => {
    return getJobTagFilter(videomaker);
  }, [videomaker]);
  return (
    <div>
      <OverviewPreviewSection title={t('landingPage.latestRenders')} showMoreLink={'/videos'}>
        <JobVideosOverview
          filter="video_maker"
          withLayoutToggles={false}
          tagFilter={jobTagFilter}
          layout="grid"
          limit={10}
          actionUrls={jobActionUrls}
          horizontalView
          canLoadMore={false}
        />
      </OverviewPreviewSection>
      {ProductOverview && (
        <OverviewPreviewSection title={t('landingPage.startWithProduct')} showMoreLink={'/video-maker'}>
          <ProductOverview
            horizontalView
            showDetail={false}
            limit={10}
            canLoadMore={false}
            onProductSelected={handleProductSelect}
            showSearch={false}
          />
        </OverviewPreviewSection>
      )}
    </div>
  );
};

export default VideoMakerOverviewContainer;
