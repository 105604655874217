import { License } from '@moovly/clients/lib/resources/licenses/licenses.interfaces';
import { UserProfile } from '@moovly/clients/lib/resources/users/users.interfaces';
import { ProfilePreview } from '@moovly/dashboard-global';
import { t } from '@moovly/i18n';
import { Button } from '@moovly/ui';
import { inject } from 'mobx-react';
import * as React from 'react';
import { RestrictedTo } from '@moovly/permissions';
import { Scope } from '@moovly/clients';
import { RootState } from '../../../store';
interface ProfileSettingsPageProps {
  user?: UserProfile;
  license?: License;
}

const ProfileSettingsPage: React.FC<ProfileSettingsPageProps> = ({ user, license }) => {
  return (
    <div>
      {user && license && (
        <ProfilePreview profile={user} license={license}>
          <RestrictedTo scope={Scope.usersUpdate}>
            <Button
              href="https://dashboard.moovly.com/settings/profile"
              target="_blank"
              color="pink"
              className="mt-4"
              label={t('profile.editProfileBtn')}
            />
          </RestrictedTo>
        </ProfilePreview>
      )}
      <hr />
    </div>
  );
};

export default inject<RootState>(({ user, license }) => ({
  user: user.data,
  license: license.data,
}))(ProfileSettingsPage);
