import { Scope } from '@moovly/clients';
import { VideoMaker } from '@moovly/integrations-core';
import { IconEdit, IconBulkDashboard } from '@moovly/ui';
import { getThemingConfig } from '@moovly/whitelabel';
import { Navigate } from 'react-router-dom';
import { Route } from '../../types/route.interfaces';
import { lazy } from 'react';
import BolComVideoMakerPage from './pages/BolComVideoMakerPage';

const AmazonVideoMakerPage = lazy(() => import('./pages/AmazonVideoMakerPage'));
const BigCommerceVideoMakerPage = lazy(() => import('./pages/BigCommerceVideoMakerPage'));
const BulkVideoMakerPage = lazy(() => import('./pages/BulkVideomakerPage'));
const ShopifyVideoMakerPage = lazy(() => import('./pages/ShopifyVideoMakerPage'));
const RssVideoMaker = lazy(() => import('./pages/RssVideoMaker'));
const StandaloneVideoMaker = lazy(() => import('./pages/StandaloneVideomaker'));

const videomaker = getThemingConfig('videomaker');
const dashboardRoutes: Route[] = [];
const videomakerArray = videomaker && (Array.isArray(videomaker) ? videomaker : [videomaker]);
const sidebar = {
  icon: IconEdit,
  id: 'videomaker',
  translationKey: 'menu.videoMaker.default',
};

if (!videomakerArray || videomakerArray.length > 1) {
  dashboardRoutes.push({
    sidebar,
    children: [
      {
        sidebar: {
          translationKey: 'menu.videoMaker.standalone',
        },
        scope: Scope.videomakerStandalone,
        path: '/video-maker/standalone',
        element: <StandaloneVideoMaker />,
      },
      {
        sidebar: {
          translationKey: 'menu.videoMaker.rss',
        },
        scope: Scope.videomakerRss,
        path: '/video-maker/rss',
        element: <RssVideoMaker />,
      },
      {
        sidebar: {
          translationKey: 'menu.videoMaker.amazon',
        },
        path: '/video-maker/amazon',
        element: <AmazonVideoMakerPage />,
      },
      {
        sidebar: {
          translationKey: 'menu.videoMaker.bigcommerce',
        },
        scope: Scope.integrationsBigCommerce,
        path: '/video-maker/bigcommerce',
        element: <BigCommerceVideoMakerPage />,
      },
      {
        sidebar: {
          translationKey: 'menu.videoMaker.shopify',
        },
        scope: Scope.integrationsShopify,
        path: '/video-maker/shopify',
        element: <ShopifyVideoMakerPage />,
      },
      {
        sidebar: {
          translationKey: 'menu.videoMaker.bolcom',
        },
        scope: Scope.integrationsBolCom,
        path: '/video-maker/bolcom',
        element: <BolComVideoMakerPage />,
      },
    ],
  });
  dashboardRoutes.push({
    path: '/video-maker',
    element: <Navigate replace to="/video-maker/amazon" />,
  });
} else if (videomakerArray.includes(VideoMaker.Amazon)) {
  dashboardRoutes.push({
    sidebar,
    path: '/video-maker/amazon',
    element: <AmazonVideoMakerPage />,
  });
  dashboardRoutes.push({
    path: '/video-maker',
    element: <Navigate replace to="/video-maker/amazon" />,
  });
} else if (videomakerArray.includes(VideoMaker.BigCommerce)) {
  dashboardRoutes.push({
    sidebar,
    path: '/video-maker/bigcommerce',
    element: <BigCommerceVideoMakerPage />,
  });
  dashboardRoutes.push({
    path: '/video-maker',
    element: <Navigate replace to="/video-maker/bigcommerce" />,
  });
} else if (videomakerArray.includes(VideoMaker.Shopify)) {
  dashboardRoutes.push({
    sidebar,
    path: '/video-maker/shopify',
    element: <ShopifyVideoMakerPage />,
  });
  dashboardRoutes.push({
    path: '/video-maker',
    element: <Navigate replace to="/video-maker/shopify" />,
  });
}

dashboardRoutes.push({
  sidebar: {
    icon: IconBulkDashboard,
    id: 'videomaker-bulk',
    translationKey: 'menu.videoMaker.bulk',
  },
  scope: Scope.videomakerBatch,
  path: '/video-maker/bulk',
  element: <BulkVideoMakerPage />,
});

export default dashboardRoutes;
