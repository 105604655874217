import { clients } from '@moovly/clients';
import { License } from '@moovly/clients/lib/resources/licenses/licenses.interfaces';
import { action, makeAutoObservable } from 'mobx';

export interface ILicenseStore {
  data?: License;
  loaded: boolean;
}

class LicenseStore implements ILicenseStore {
  loaded = false;
  data?: License;
  constructor() {
    makeAutoObservable(this, {
      fetchLicense: action.bound,
    });
  }

  fetchLicense() {
    clients.Licenses.getUserLicense().then((data) => {
      this.data = data;
      this.loaded = true;
    });
  }
}

export default new LicenseStore();
