import { Integration } from '@moovly/clients';
import { t } from '@moovly/i18n';
import { VideoMaker, useIntegrations } from '@moovly/integrations-core';
import { Spinner } from '@moovly/ui';
import { getThemingConfig } from '@moovly/whitelabel';
import { inject } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { videoMakers } from '../../../config/videomaker';
import useVideoMaker from '../../../hooks/useVideoMaker';
import { RootState } from '../../../store';
import { IntegrationButtonContainer } from '../../integrations';
import FirstTimeGreeting, { FirstTimeGreetingButton } from '../components/FirstTimeGreeting';
import SuccessfullyConnected from '../components/SuccessfullyConnected';
import VideoMakerOverviewContainer from './VideoMakerOverviewContainer';

interface DashboardContainerProps {
  integrations?: Integration[];
}

const DashboardContainer: React.FC<DashboardContainerProps> = ({ integrations }) => {
  const { isShopify } = useVideoMaker();
  const { userIntegrationsLoading, userIntegrations } = useIntegrations();
  const isConnected = React.useMemo(
    () => integrations?.some((i) => !!userIntegrations[i]),
    [integrations, userIntegrations],
  );
  const { getIntegration } = useIntegrations();
  const [justConnected, setJustConnected] = React.useState(false);
  const handleJustConnected = React.useCallback(() => {
    setJustConnected(true);
  }, []);
  if (userIntegrationsLoading) {
    return <Spinner />;
  }
  if (justConnected) {
    return <SuccessfullyConnected />;
  }
  if (!isConnected && integrations?.length === 1) {
    const integration = getIntegration(integrations[0]);
    return (
      <div>
        <FirstTimeGreeting
          instructions={isShopify ? t('videoMaker.shopify.instructions') : undefined}
          connectButton={
            <FirstTimeGreetingButton src={integration?.image}>
              <IntegrationButtonContainer
                integration={integrations[0]}
                label={t('landingPage.welcome.cta')}
                buttonAtBottomPage
                onConnected={handleJustConnected}
              />
              {integration?.type === Integration.AmazonSeller && (
                <Link to="/video-maker">{t('landingPage.amazon_seller.cta.link')}</Link>
              )}
            </FirstTimeGreetingButton>
          }
        />
      </div>
    );
  } else if (!isConnected) {
    return (
      <div>
        <FirstTimeGreeting
          connectButton={
            <FirstTimeGreetingButton src="/images/integrations/amazon-seller.png">
              <IntegrationButtonContainer
                integration={Integration.AmazonSeller}
                label={t('landingPage.welcome.cta')}
                buttonAtBottomPage
                onConnected={handleJustConnected}
              />
              <Link to="/video-maker">{t('landingPage.amazon_seller.cta.link')}</Link>
            </FirstTimeGreetingButton>
          }
        />
      </div>
    );
  }
  return <VideoMakerOverviewContainer />;
};

export default inject<RootState>(() => {
  const videomaker = getThemingConfig('videomaker');
  const items = videomaker
    ? ((Array.isArray(videomaker) ? videomaker : [videomaker]) as VideoMaker[])
    : Object.values(VideoMaker);
  const integrationItems = items.map((item) => videoMakers[item].integration);
  return {
    integrations: integrationItems,
  };
})(DashboardContainer);
