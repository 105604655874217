import { t } from '@moovly/i18n';
import * as React from 'react';

import './SidebarBottom.scss';
import { useCredits } from '@moovly/credits';
import { Link } from 'react-router-dom';

const SidebarBottom: React.FC = () => {
  const { availableCredits } = useCredits();
  return (
    <div className="SidebarBottom">
      <Link className="btn btn-secondary" to="/settings/credits">
        {t('sidebarBottom.creditsLeft.button')}
      </Link>
      <p className="help">{t('sidebarBottom.creditsLeft.info', { credits: availableCredits })}</p>
    </div>
  );
};

export default SidebarBottom;
