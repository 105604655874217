import { OverviewDashboardIcon } from '@moovly/ui';
import * as React from 'react';
import { isInIframe } from '../../hooks/useIframe';
import { Route } from '../../types/route.interfaces';
import DashboardPage from './pages/DashboardPage';

const dashboardRoutes: Route[] = [
  {
    sidebar: {
      icon: OverviewDashboardIcon,
      translationKey: 'menu.dashboard',
    },
    path: '/dashboard',
    element: <DashboardPage />,
  },
];

export default isInIframe() ? [] : dashboardRoutes;
