import { t } from '@moovly/i18n';
import { WhitelabelLogin, WhitelabelRegister } from '@moovly/whitelabel';
import * as React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardContainer from './containers/DashboardContainer';
import useVideoMaker from './hooks/useVideoMaker';
import modules from './modules';
import ActivateAccount from './pages/ActivateAccount';
import SuccesfullyActivatedThirdParty from './pages/SuccesfullyActivatedThirdParty';
import { Route } from './types/route.interfaces';
import { Spinner } from '@moovly/ui';
import { useSentryRoutes } from './services/Sentry';

interface RouterProps {
  isLoggedIn?: boolean;
}
const globalRoutes = [
  {
    path: 'succesfully_authenticated/:slug',
    element: <SuccesfullyActivatedThirdParty />,
  },
  {
    path: 'activate',
    element: <ActivateAccount />,
  },
];

const Router: React.FC<RouterProps> = ({ isLoggedIn }) => {
  const { isShopify } = useVideoMaker();
  const routeArray = React.useMemo(
    () =>
      isLoggedIn
        ? [
            ...Object.values(modules).flatMap((module) => {
              const result: { path: string; element: any }[] = [];
              const checkRoutes = (routes: Route[]) => {
                routes.forEach((route) => {
                  if (route.path) {
                    result.push({ path: route.path, element: route.element });
                  }
                  if (route.children) {
                    checkRoutes(route.children);
                  }
                });
              };
              checkRoutes(module.routes);
              return result;
            }),
            ...globalRoutes,
          ]
        : [
            {
              path: '/login',
              element: <WhitelabelLogin instructions={isShopify ? t('videoMaker.shopify.instructions') : undefined} />,
            },
            {
              path: '/register',
              element: <WhitelabelRegister />,
            },
            ...globalRoutes,
          ],
    [isLoggedIn, isShopify],
  );
  const routes = useSentryRoutes([
    ...routeArray,
    {
      path: '*',
      element: <Navigate replace to={routeArray[0].path} />,
    },
  ]);

  if (isLoggedIn) {
    return (
      <DashboardContainer>
        <React.Suspense fallback={<Spinner />}>{routes}</React.Suspense>
      </DashboardContainer>
    );
  }
  return routes;
};

export default Router;
