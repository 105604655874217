import { clients } from '@moovly/clients';
import { AccessUserResponse } from '@moovly/clients/lib/resources/access/access.interfaces';
import { UserProfile } from '@moovly/clients/lib/resources/users/users.interfaces';
import { action, computed, makeAutoObservable } from 'mobx';

export interface IUserStore {
  loaded: boolean;
  data: UserProfile | null;
  thirdpartyAccessTokens: any;
  accessLoaded: boolean;
  access?: AccessUserResponse;
}

class UserStore implements IUserStore {
  loaded = false;
  data: UserProfile | null = null;
  accessLoaded = false;
  access?: AccessUserResponse;
  constructor() {
    makeAutoObservable(this, {
      fetchUser: action.bound,
      fetchAccess: action.bound,
      logout: action.bound,
      thirdpartyAccessTokens: computed,
      redirectDone: action.bound,
    });
  }
  get thirdpartyAccessTokens() {
    if (this.data?.third_party_access_tokens) {
      return { ...this.data.third_party_access_tokens };
    }
    return {};
  }

  redirectDone() {
    if (!this.data) {
      return;
    }
    this.data.redirect_url = undefined;
  }

  logout() {
    this.data = null;
  }

  fetchUser() {
    return clients.Users.getUserProfile().then((data) => {
      this.data = data;
      this.loaded = true;
    });
  }

  fetchAccess() {
    clients.Access.getUserMe().then((data) => {
      this.access = data;
      this.accessLoaded = true;
    });
  }
}

export default new UserStore();
