import { clients } from '@moovly/clients';
import { Settings } from '@moovly/clients/lib/resources/settings/settings.interfaces';
import { action, makeAutoObservable } from 'mobx';

export interface ISettingsStore {
  data?: Settings;
  loaded: boolean;
}

class SettingsStore implements ISettingsStore {
  loaded = false;
  data?: Settings;
  constructor() {
    makeAutoObservable(this, {
      fetchSettings: action.bound,
    });
  }

  fetchSettings() {
    clients.Settings.getUserSettings().then((data) => {
      this.data = data;
      this.loaded = true;
    });
  }
}

export default new SettingsStore();
