import LicenseStore, { ILicenseStore } from './LicenseStore';
import SettingsStore, { ISettingsStore } from './SettingsStore';
import UserStore, { IUserStore } from './UserStore';

export type RootState = {
  user: IUserStore;
  license: ILicenseStore;
  settings: ISettingsStore;
};

const rootStore: RootState = {
  user: UserStore,
  license: LicenseStore,
  settings: SettingsStore,
};
export { UserStore, LicenseStore, SettingsStore };
export default rootStore;
