import { OAuth } from '@moovly/clients';
import * as React from 'react';
import { SettingsStore, UserStore, LicenseStore } from '../store';

OAuth.create({
  clientId: import.meta.env.MOOVLY_APP_OAUTH_CLIENT_ID,
  redirectUri: window.location.origin,
});

const useAuth = () => {
  const [authChecked, setAuthChecked] = React.useState(false);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  React.useEffect(() => {
    if (isAuthenticated) {
      UserStore.fetchUser().catch((err) => {
        setAuthChecked(false);
        setIsAuthenticated(false);
        OAuth.getTokenSilently().catch((e) => {});
      });
      UserStore.fetchAccess();
      LicenseStore.fetchLicense();
      SettingsStore.fetchSettings();
    }
  }, [isAuthenticated]);
  React.useEffect(() => {
    OAuth.onLoggedIn = () => {
      setIsAuthenticated(true);
    };

    OAuth.onLogout = () => {
      setIsAuthenticated(false);
      UserStore.logout();
    };
    OAuth.onTokenUpdate = () => {
      setAuthChecked(true);

      setIsAuthenticated(true);
    };
    OAuth.onFailedAuthentication = () => {
      setAuthChecked(true);
      setIsAuthenticated(false);
    };
    OAuth.isAuthenticated().then((authenticated) => {
      if (authenticated) {
        setIsAuthenticated(true);
      }
      setAuthChecked(true);
    });
  }, []);

  return {
    authChecked,
    isAuthenticated,
    userId: isAuthenticated && OAuth.userUuid,
  };
};

export default useAuth;
