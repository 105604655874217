import { Integration } from '@moovly/clients';
import { SuccesfullyActivatedThirdPartyPage } from '@moovly/integrations-core';
import * as React from 'react';
import { useParams } from 'react-router-dom';

const SuccesfullyActivatedThirdParty: React.FC = (props) => {
  const { slug } = useParams();
  return <SuccesfullyActivatedThirdPartyPage slug={slug as Integration} />;
};

export default SuccesfullyActivatedThirdParty;
