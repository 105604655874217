import * as React from 'react';
import './LayoutWithoutSidebar.scss';
import { Outlet } from 'react-router-dom';
import { WhitelabelLogo } from '@moovly/whitelabel';
import { Footer } from '@moovly/dashboard-global';
interface LayoutWithoutSidebarProps {
  children: React.ReactNode;
}

const LayoutWithoutSidebar: React.FC<LayoutWithoutSidebarProps> = ({ children }) => {
  return (
    <>
      <div className="SinglePage">
        <div className="Logo">
          <WhitelabelLogo />
        </div>

        <div className="SinglePage--Content">
          <div>{children || <Outlet />}</div>
        </div>
      </div>
      <div className="SinglePage--Footer">
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default LayoutWithoutSidebar;
