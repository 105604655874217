import * as React from 'react';
import { init, setUser, reactRouterV6Instrumentation, wrapUseRoutes, captureMessage } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ExtraErrorData } from '@sentry/integrations';
import { denyUrls, ignoreErrors } from '@moovly/clients';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes, useRoutes } from 'react-router-dom';

export const useSentryRoutes = wrapUseRoutes(useRoutes);

class SentryService {
  init() {
    if (import.meta.env.MODE === 'development') {
      return;
    }
    init({
      environment: import.meta.env.MOOVLY_APP_SENTRY_ENVIRONMENT,
      integrations: [
        new ExtraErrorData(),
        new BrowserTracing({
          tracePropagationTargets: ['api.moovly.com', /services.moovly.com/, /^\/api/],
          routingInstrumentation: reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
        // new Replay({
        //   // Additional SDK configuration goes in here, for example:
        //   maskAllText: true,
        //   blockAllMedia: true,
        // }),
      ],

      // replaysSessionSampleRate: 0,
      // replaysOnErrorSampleRate: 0,
      tracesSampleRate: 0.05,
      dsn: 'https://bb8e1c9cba704e4f839c645c16979f5e@o101602.ingest.sentry.io/4504881317412864',
      ignoreErrors: [
        ...ignoreErrors,
        /Non-Error promise rejection captured with keys/,
        /AbortError/,
        /Unexpected token \[ in JSON at position/,
        /Failed to execute 'removeChild' on 'Node'/,
        /Non-Error promise rejection captured with value/,
        /NetworkError/,
        /Failed to fetch/,
      ],
      denyUrls: [...denyUrls, /e.clarity.ms/i],
    });
  }
  captureMessage(message: string) {
    captureMessage(message);
  }
  setUser(userId: string) {
    setUser({ id: userId });
  }
}
export default new SentryService();
