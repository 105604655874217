import { SettingsDashboardIcon } from '@moovly/ui';
import { Route } from '../../types/route.interfaces';
import ProfileSettingsPage from './pages/ProfileSettingsPage';
import { Scope } from '@moovly/clients';
import CreditSettingsPage from './pages/CreditSettingsPage';
import { isInIframe } from '../../hooks/useIframe';
import PreferencesSettings from './pages/Preferences';
import InstegrationSettingsPage from './pages/IntegrationSettingsPage';
import { getThemingConfig } from '@moovly/whitelabel';
import { VideoMaker } from '@moovly/integrations-core';

const videomaker = getThemingConfig('videomaker');
const videomakerArray = videomaker && (Array.isArray(videomaker) ? videomaker : [videomaker]);

const dashboardRoutes: Route[] = [
  {
    sidebar: {
      icon: SettingsDashboardIcon,
      translationKey: 'menu.settings.label',
    },
    children: [
      {
        sidebar: {
          translationKey: 'menu.settings.profile',
        },
        path: '/settings/profile',
        element: <ProfileSettingsPage />,
      },
      !isInIframe() &&
        (!videomakerArray || videomakerArray.length !== 1 || !videomakerArray.includes(VideoMaker.Shopify)) && {
          sidebar: {
            translationKey: 'menu.settings.integrations',
          },
          path: '/settings/connections',
          element: <InstegrationSettingsPage />,
        },
      {
        sidebar: {
          translationKey: 'menu.settings.preferences',
        },
        path: '/settings/preferences',
        scope: Scope.dashboardNavigationBrandSettings,
        element: <PreferencesSettings />,
      },

      {
        sidebar: {
          translationKey: 'menu.settings.credits',
        },
        scope: Scope.dashboardNavigationCreditSettings,
        path: '/settings/credits',
        element: <CreditSettingsPage />,
      },
    ].filter((c) => !!c) as Route[],
  },
];

export default dashboardRoutes;
