import dashboardModule from './dashboard';
import videoMakerModule from './videoMaker';
import rendersModule from './renders';
import settingsModule from './settings';
import premoderationModule from './preModeration';

const modules = {
  dashboardModule,
  videoMakerModule,
  premoderationModule,

  rendersModule,
  settingsModule,
};
export default modules;
export { dashboardModule, videoMakerModule, rendersModule, settingsModule, premoderationModule };
