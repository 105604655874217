import { LanguageSelectDropdown, NavProfileToggle } from '@moovly/dashboard-global';
import * as React from 'react';

interface TopRightProps {
  onLogout: () => void;
  email: string;
}

const TopRight: React.FC<TopRightProps> = ({ email, onLogout }) => {
  return (
    <div className="d-flex">
      <LanguageSelectDropdown locales={['en', 'nl', 'zh-CN']} />
      <div className="ms-3">
        <NavProfileToggle email={email} onLogout={onLogout} profilePath={'/settings/profile'} />
      </div>
    </div>
  );
};

export default TopRight;
