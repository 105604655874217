import { VideoMaker } from '@moovly/integrations-core';
import { useWhitelabelProperty } from '@moovly/whitelabel';
import * as React from 'react';

const useVideoMaker = () => {
  const videomaker = useWhitelabelProperty('videomaker');
  return React.useMemo(() => {
    return {
      isShopify: videomaker === VideoMaker.Shopify,
      isBigCommerce: videomaker === VideoMaker.BigCommerce,
      isAmazon: videomaker === VideoMaker.Amazon,
    };
  }, [videomaker]);
};

export default useVideoMaker;
