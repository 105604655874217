import * as React from 'react';
import { JobVideosOverview } from '@moovly/dashboard-renders';
import { t } from '@moovly/i18n';
import Page from '../../../../components/Page';
import { useWhitelabelProperty } from '@moovly/whitelabel';
import { VideoMaker } from '@moovly/integrations-core';
import { getJobTagFilter } from '../../../../config/videomaker';

export const jobActionUrls = {
  translate: '/videos/:id/translate',
  review: '/videos/:id/review',
  edit: '/videos/:id/edit',
  createProject: 'https://dashboard.moovly.com/projects/:id',
};
const OwnJobsOverview = () => {
  const videomaker = useWhitelabelProperty<VideoMaker | VideoMaker[]>('videomaker');
  const tagFilter = getJobTagFilter(videomaker);
  return (
    <Page>
      <JobVideosOverview
        title={t('jobs.own.title')}
        withLayoutToggles={false}
        tagFilter={tagFilter}
        showSource={tagFilter === 'video_maker'}
        actionUrls={jobActionUrls}
      />
    </Page>
  );
};

export default OwnJobsOverview;
