import { IconPreModeration } from '@moovly/ui';
import { getThemingConfig } from '@moovly/whitelabel';
import { lazy } from 'react';
import { Route } from '../../types/route.interfaces';

const PreModerationOverview = lazy(() => import('./pages/PreModerationOverview'));
const PreModerationUpload = lazy(() => import('./pages/PreModerationUpload'));

const videomaker = getThemingConfig('videomaker');
const videomakerArray = videomaker && (Array.isArray(videomaker) ? videomaker : [videomaker]);

const routes: Route[] = [];

if (!videomakerArray || videomakerArray.includes('amazon')) {
  routes.push({
    sidebar: {
      icon: IconPreModeration,
      translationKey: 'menu.premoderation.default',
    },
    children: [
      {
        sidebar: {
          translationKey: 'menu.premoderation.amazon',
        },
        path: '/premoderation/amazon',
        element: <PreModerationUpload />,
      },
      {
        sidebar: {
          translationKey: 'menu.premoderation.videos',
        },
        path: '/premoderation/videos',
        element: <PreModerationOverview />,
      },
    ],
  });
}

export default routes;
