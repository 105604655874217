import { Integration, UrlSearch } from '@moovly/clients';
import { LicenseSettings } from '@moovly/clients/lib/resources/settings/settings.interfaces';
import { UserProfile } from '@moovly/clients/lib/resources/users/users.interfaces';
import { CreditPackOverview, CreditSummary, CreditHistory, CreditCustomAmountBuy } from '@moovly/credits';
import { showSuccessAlert } from '@moovly/dashboard-global';
import { t, tHtml } from '@moovly/i18n';
import { inject } from 'mobx-react';
import * as React from 'react';
import Page from '../../../components/Page';
import useVideoMaker from '../../../hooks/useVideoMaker';
import { RootState } from '../../../store';
import { handleCustomShopifyCreditsBuy } from '../utils/shopifyCreditsBuy';
import { useHasIntegration } from '@moovly/integrations-core';

interface CreditSettingsProps {
  user?: UserProfile;
  licenseSettings?: LicenseSettings;
}

const CreditSettingsPage: React.FC<CreditSettingsProps> = ({ user, licenseSettings }) => {
  const { isShopify } = useVideoMaker();
  const { hasIntegration: hasShopifyIntegration } = useHasIntegration(Integration.Shopify);
  const customShopifyConnection = React.useMemo(
    () => isShopify && hasShopifyIntegration,
    [isShopify, hasShopifyIntegration],
  );
  React.useEffect(() => {
    if (isShopify) {
      if (UrlSearch.get('success') === 'true') {
        showSuccessAlert({
          title: t('credits.shopify.success.title'),
          message: t('credits.shopify.success.description'),
        });
        UrlSearch.remove('success');
      }
    }
  }, [isShopify]);

  return (
    <Page title={t('credits.page.title')} description={tHtml('credits.page.description')}>
      <CreditSummary />

      {user && (
        <>
          {licenseSettings && licenseSettings.credits_custom_price ? (
            <CreditCustomAmountBuy
              onBuyCredits={customShopifyConnection ? handleCustomShopifyCreditsBuy : undefined}
              pricePerCredit={licenseSettings.credits_price_per_unit!}
              minimumAmount={licenseSettings.credits_minimum_amount!}
            />
          ) : (
            <CreditPackOverview
              user={user}
              onBuyCredits={customShopifyConnection ? handleCustomShopifyCreditsBuy : undefined}
            />
          )}
        </>
      )}
      <CreditHistory />
    </Page>
  );
};
export default inject<RootState>(({ user, settings }) => ({
  user: user.data,
  licenseSettings: settings.data?.license,
}))(CreditSettingsPage);
