import { Tabs } from '@moovly/dashboard-global';
import * as React from 'react';
import PreferencesBrandsPage from './PreferencesBrandsPage';

const PreferencesSettings: React.FC = () => {
  return (
    <Tabs
      tabs={[
        {
          value: 'brand',
          label: 'Brand',
          screen: PreferencesBrandsPage,
        },
      ]}
    />
  );
};
export default PreferencesSettings;
