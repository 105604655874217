import './lazyLoadRetry';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main.scss';
import { t } from '@moovly/i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { configuration, getDefaultMiddleWare } from '@moovly/clients';
import { checkClientForStaging } from './utils/staging';
import SentrySevice from './services/Sentry';

configuration.setMiddlewares(getDefaultMiddleWare(t));
checkClientForStaging();
SentrySevice.init();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
