import { Integration } from '@moovly/clients';
import { SelectAmazonProductFromStore } from '@moovly/integrations-amazon';
import { SelectBigCommerceProductFromStore } from '@moovly/integrations-bigcommerce';
import { SelectBolComProductFromStore } from '@moovly/integrations-bolcom';
import { VideoMaker } from '@moovly/integrations-core';
import { SelectShopifyProductFromStore } from '@moovly/integrations-shopify';

export const videoMakers: {
  [key in VideoMaker]: {
    integration: Integration;
    job_tag: string;
    overviewPage: React.FC<any>;
  };
} = {
  [VideoMaker.Amazon]: {
    integration: Integration.AmazonSeller,
    job_tag: 'amazon_video_maker',
    overviewPage: SelectAmazonProductFromStore,
  },
  [VideoMaker.BigCommerce]: {
    integration: Integration.BigCommerce,
    job_tag: 'bigcommerce_video_maker',
    overviewPage: SelectBigCommerceProductFromStore,
  },
  [VideoMaker.Shopify]: {
    integration: Integration.Shopify,
    job_tag: 'shopify_video_maker',
    overviewPage: SelectShopifyProductFromStore,
  },
  [VideoMaker.BolCom]: {
    integration: Integration.BolCom,
    job_tag: 'bolcom_video_maker',
    overviewPage: SelectBolComProductFromStore,
  },
};
export const DEFAULT_JOB_TAG = 'video_maker';

export const getJobTagFilter = (videoMaker: VideoMaker | VideoMaker[]) => {
  const videoMakerArray = videoMaker && Array.isArray(videoMaker) ? videoMaker : [videoMaker];
  if (!videoMaker || !videoMakerArray.length || videoMakerArray.length > 1) {
    return DEFAULT_JOB_TAG;
  }
  const first = videoMakerArray[0];
  //@ts-ignore
  return videoMakers[first] ? videoMakers[first].job_tag : DEFAULT_JOB_TAG;
};
export const DEFAULT_JOB_OVERVIEW_PAGE = null;

export const getJobOverviewPage = (videoMaker: VideoMaker | VideoMaker[], integrations?: any) => {
  let videoMakerArray: VideoMaker[] =
    (videoMaker && (Array.isArray(videoMaker) ? videoMaker : [videoMaker])) || Object.keys(videoMakers);
  if (!videoMakerArray.length) {
    videoMakerArray = Object.keys(videoMakers) as VideoMaker[];
  }

  if (videoMakerArray.length === 1) {
    return videoMakers[videoMakerArray[0]].overviewPage;
  }
  const key = videoMakerArray.find((videoMaker) => {
    return integrations && integrations[videoMakers[videoMaker].integration];
  });
  return key ? videoMakers[key].overviewPage : null;
};
