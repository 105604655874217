import i18n from '@moovly/i18n';
import * as React from 'react';

i18n.init(`${i18n.i18nCdnUrl}/videoMaker`, {
  debug: import.meta.env.MODE !== 'production',
});
i18n.setGlobalParam('platformName', 'Moovly Video Maker');

const useI18n = ({ userLanguage, isAuthenticated }: { userLanguage?: string; isAuthenticated?: boolean } = {}) => {
  const [i18nLoaded, setI18nLoaded] = React.useState(false);
  React.useEffect(() => {
    if (i18n.loaded) {
      setI18nLoaded(true);
    } else {
      i18n.on('loaded', (data: any) => {
        if (data[i18n.currentLanguage]) {
          setI18nLoaded(true);
        }
      });
    }
  }, []);
  React.useEffect(() => {
    if (!isAuthenticated && !i18n.loaded) {
      i18n.load();
    }
  }, [isAuthenticated]);
  React.useEffect(() => {
    if (userLanguage) {
      i18n.setLanguage(userLanguage);
      i18n.load();
    }
  }, [userLanguage]);

  return i18nLoaded;
};

export default useI18n;
